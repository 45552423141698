@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.hide-scrollbar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.hide-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.alert-enter {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

.alert-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.alert-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit-active {
  opacity: 0;
  transform: translateY(-20px);
}

/* MultiSelect.css */

.rmsc.multiselect {
  /* Set the border color to black */
  --rmsc-border: #000;

  /* Set the text color to grey */
  --rmsc-gray: #888;

  /* Remove rounded corners to make the border a rectangle */
  border-radius: 0;

  /* Set the font size for list items */
  font-size: 14px;
}

.rmsc.multiselect .option {
  /* Customize the text color of the list items */
  color: var(--rmsc-gray);
}

.underline-text {
  text-decoration: underline;
}

.custom-file-upload {
  display: inline-block;
  position: relative;
  background: white;
  color: black;
  padding: 10px 20px;
  cursor: pointer;

  /* margin: 10px 0; */
  text-align: start;
  border: 1px solid #000;
}

.custom-file-upload input[type="file"] {
  display: none;
}

/* For webkit browsers like Chrome and Safari */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #4318ff;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
